@charset "UTF-8";
/*
//info from https://www.fonts.com/web-fonts/projects and https://www.fonts.com/web-projects
font-family:'ITC Galliard eText W01';
font-family:'DIN Next W01';
font-family:'DIN Next™ W01';

ITC Galliard® eText W01 Roman
------------------------------
font-family:'ITC Galliard eText W01';
font-weight:400;
font-style:normal;

ITC Galliard® eText W01 Italic
-------------------------------
font-family:'ITC Galliard eText W01';
font-weight:400;
font-style:italic;

ITC Galliard® eText W01 Bold
-----------------------------
font-family:'ITC Galliard eText W01';
font-weight:700;
font-style:normal;

ITC Galliard® eText W01 Bold Italic
------------------------------------
font-family:'ITC Galliard eText W01';
font-weight:700;
font-style:italic;



DIN Next™ W01 Condensed Medium
--------------------------------
font-family:'DIN Next W01';
font-weight:500;
font-style:normal;



DIN Next™ W01 Regular
-----------------------
font-family:'DIN Next W01';
font-weight:400;
font-style:normal;

DIN Next™ W01 Italic
---------------------
font-family:'DIN Next™ W01';
font-weight:400;
font-style:italic;

DIN Next™ W01 Medium
----------------------
font-family:'DIN Next W01';
font-weight:700;
font-style:normal;

DIN Next™ W01 Medium Italic
-----------------------------
font-family:'DIN Next™ W01';
font-weight:700;
font-style:italic;


*/
.d-print-none,
.icon,
.btn-toggle-nav,
.nav-container,
.lg-search,
.breadcrumb,
.contact-form,
.carousel,
.message,
.related-links,
.block.navigation,
.block.shortcuts,
.block.documents,
.pagination,
.header-tab:not(.active) p,
.employees-search,
.docs-list-group .figure-img,
.employee-id img,
.fund-links img,
.fund-groups .figure-img,
.teaser .figure,
.anchor-links,
.bottom-illustration,
.manager-card,
.page-header form,
.global-footer {
  display: none; }

/*global rules*/
*,
*:before,
*:after {
  background: transparent !important;
  color: #000 !important;
  /* Black prints faster: http://www.sanbeiji.com/archives/953 */
  box-shadow: none !important;
  text-shadow: none !important; }

body {
  font-family: "ITC Galliard eText W01", serif;
  line-height: 1.5;
  font-size: 11pt; }

h1, .h1,
h2, .h2,
h3,
.diagram .custom-select, .h3 {
  font-family: "DIN Next W01", sans-serif;
  font-weight: 500; }

h1, .h1 {
  line-height: 1.2;
  font-size: 35pt;
  margin: 0 0 5pt; }

h2, .h2 {
  line-height: 1.2;
  font-size: 25pt;
  margin: 0 0 5pt; }


h3,
.diagram .custom-select, .h3 {
  line-height: 1.2;
  font-size: 20pt;
  margin: 0 0 5pt; }

h4, .h4 {
  line-height: 1.2;
  font-size: 12pt;
  font-family: "DIN Next W01", sans-serif;
  font-weight: normal;
  margin: 0 0 5pt; }

p {
  margin: 0 0 10pt; }

/*links in content*/
a,
a:visited {
  text-decoration: underline; }

a[href]:after {
  content: " (" attr(href) ") "; }

a[href^="#"]:after,
a[href^="javascript:"]:after {
  content: ""; }

.lead {
  font-weight: bold;
  font-size: 13pt;
  line-height: 1.5; }

.blockquote {
  font-weight: bold;
  font-size: 13pt;
  line-height: 1.5; }
  .blockquote .blockquote-footer {
    font-family: "DIN Next W01", sans-serif;
    font-weight: normal;
    font-size: 11pt; }

img {
  max-width: 100%;
  height: auto; }

tr,
img,
blockquote {
  page-break-inside: avoid; }

.global-header .logo-link {
  display: block;
  margin: 0 0 15pt; }
  .global-header .logo-link:after {
    content: ''; }
  .global-header .logo-link .logo {
    width: 200px; }

.figure {
  margin: 20pt; }
  .figure.figure-large {
    margin: 10pt 0; }
  .figure.figure-small {
    width: 33%; }
  .figure .figure-caption {
    font-size: 10pt; }

.search-result-list {
  padding: 0; }
  .search-result-list li {
    margin: 0 0 15pt; }
    .search-result-list li h2 {
      margin: 0; }
    .search-result-list li p {
      margin: 0; }
  .search-result-list a:after {
    content: ''; }

.date {
  font-family: "DIN Next W01", sans-serif;
  font-weight: bold; }

.employee-card {
  margin-bottom: 10pt; }
  .employee-card img {
    display: none; }
  .employee-card h3, .employee-card .diagram .custom-select, .diagram .employee-card .custom-select {
    font-size: 18pt;
    margin-bottom: 0; }

ul.contact-list,
ul.contact-office-list {
  padding: 0;
  list-style-type: none;
  margin: 0 0 10pt; }
  ul.contact-list .list-link,
  ul.contact-office-list .list-link {
    margin: 0; }
    ul.contact-list .list-link a,
    ul.contact-office-list .list-link a {
      text-decoration: none; }
      ul.contact-list .list-link a:after,
      ul.contact-office-list .list-link a:after {
        content: ''; }

.contact-block {
  margin: 0 0 10pt; }
  .contact-block .img-lead {
    display: none; }

.office-card img {
  display: none; }

.table {
  width: 100%;
  max-width: 100%;
  font-family: "DIN Next W01", sans-serif;
  margin: 0 0 10pt;
  border-collapse: collapse; }
  .table th {
    font-weight: bold;
    text-align: left;
    border-bottom: thin solid black; }
  .table td {
    text-align: left;
    border-bottom: thin solid black; }
  .table.table-fund-group .column-2 {
    text-align: center; }
  .table.table-fund-group .column-4,
  .table.table-fund-group .column-5,
  .table.table-fund-group .column-6,
  .table.table-fund-group .column-7,
  .table.table-fund-group .column-8 {
    text-align: right; }
  .table.table-fund-group a {
    text-decoration: none; }
    .table.table-fund-group a:after {
      display: none; }

.diagram .diagram-label,
.diagram .class-list,
.diagram .date-box,
.diagram .interval-buttons {
  display: none; }

.diagram .custom-select {
  text-decoration: none; }

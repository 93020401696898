@import "scss/variables";

.d-print-none,
.icon,
.btn-toggle-nav,
.nav-container,
.lg-search,
.breadcrumb,
.contact-form,
.carousel,
.message,
.related-links,
.block.navigation,
.block.shortcuts,
.block.documents,
.pagination,
.header-tab:not(.active) p,
.employees-search,
.docs-list-group .figure-img,
.employee-id img,
.fund-links img,
.fund-groups .figure-img,
.teaser .figure,
.anchor-links,
.bottom-illustration,
.manager-card,
.page-header form,
.global-footer {
  display: none;
}

/*global rules*/
*,
*:before,
*:after {
  background: transparent !important;
  color: #000 !important; /* Black prints faster: http://www.sanbeiji.com/archives/953 */
  box-shadow: none !important;
  text-shadow: none !important;
}

body {
  font-family: $pam-galliard;
  line-height: 1.5;
  font-size: 11pt;
}

h1, .h1,
h2, .h2,
h3, .h3 {
  font-family: $pam-din-next;
  font-weight: 500;
}

h1, .h1 {
  line-height: 1.2;
  font-size: 35pt;
  margin: 0 0 5pt;
}

h2, .h2 {
  line-height: 1.2;
  font-size: 25pt;
  margin: 0 0 5pt;
}

h3, .h3 {
  line-height: 1.2;
  font-size: 20pt;
  margin: 0 0 5pt;
}

h4, .h4 {
  line-height: 1.2;
  font-size: 12pt;
  font-family: $pam-din-next;
  font-weight: normal;
  margin: 0 0 5pt;
}

p {
  margin: 0 0 10pt;
}

/*links in content*/
a,
a:visited {
  text-decoration: underline;
}
a[href]:after {
  content: " (" attr(href) ") ";
}
a[href^="#"]:after,
a[href^="javascript:"]:after {
  content: "";
}


.lead {
  font-weight: bold;
  font-size: 13pt;
  line-height: 1.5;
}

.blockquote {
  font-weight: bold;
  font-size: 13pt;
  line-height: 1.5;

  .blockquote-footer {
    font-family: $pam-din-next;
    font-weight: normal;
    font-size: 11pt;
  }
}

img {
  max-width: 100%;
  height: auto;
}

tr,
img,
blockquote {
  page-break-inside: avoid;
}

.global-header {

  .logo-link {
    display: block;
    margin: 0 0 15pt;

    &:after {
      content: '';
    }

    .logo {
      width: 200px;
    }
  }
}

.figure {
  margin: 20pt;

  &.figure-large {
    margin: 10pt 0;
  }

  &.figure-small {
    width: 33%;
  }

  .figure-caption {
    font-size: 10pt;
  }
}

.search-result-list  {
  padding: 0;

  li {
    margin: 0 0 15pt;
    h2 {
      margin: 0;
    }
    p {
      margin: 0;
    }
  }

  a:after {
    content: '';
  }
}

.date {
  font-family: $pam-din-next;
  font-weight: bold;
}

.employee-card {
  margin-bottom: 10pt;

  img {
    display: none;
  }

  h3 {
    font-size: 18pt;
    margin-bottom: 0;
  }


}

ul.contact-list,
ul.contact-office-list {
  padding: 0;
  list-style-type: none;
  margin: 0 0 10pt;
  .list-link {
    margin: 0;
    a {
      text-decoration: none;
      &:after {
        content: '';
      }
    }
  }
}


.contact-block {
  margin: 0 0 10pt;
  .img-lead {
    display: none;
  }
}


.office-card {
  img {
    display: none;
  }
}

.table {
  width: 100%;
  max-width: 100%;
  font-family: $pam-din-next;
  margin: 0 0 10pt;
  border-collapse: collapse;

  th {
    font-weight: bold;
    text-align: left;
    border-bottom: thin solid black;
  }

  td {
    text-align: left;
    border-bottom: thin solid black;
  }

  &.table-fund-group {
    .column-2 {
      text-align: center;
    }

    .column-4,
    .column-5,
    .column-6,
    .column-7,
    .column-8 {
      text-align: right;
    }

    a {
      text-decoration: none;
      &:after {
        display: none;
      }
    }
  }

}

.diagram {
  .diagram-label,
  .class-list,
  .date-box,
  .interval-buttons {
    display: none;
  }

  .custom-select {
    @extend h3;
    text-decoration: none;
  }

}